<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
    <div class="bottom-right-btn text-center">
      <CCollapse :show="showBtn" :duration="400">
          <!-- <a target="_blank" href="https://oemdocs.gitbook.io/online-exam-manager-documentation/">
            <CButton shape="pill" color="zoomblue" style="height:90px;width:120px"> 
              <CIcon :height="1000" name="cil-book"/><br>
              คู่มือการใช้งาน&nbsp;&nbsp;
            </CButton>
          </a><br><br> -->

          <!-- <a target="_blank" href="https://forms.office.com/Pages/ResponsePage.aspx?id=3_GBz1neKUyR2qLf0EqnUczqxTXCh8JInYUq-w4ZrfhUM045MEhSTFk3UlRMWkVKVFNZMVlNVUpFRS4u">
            <CButton shape="pill" color="zoomblue" style="height:90px;width:120px"> 
              <CIcon :height="1000" name="cil-comment-square"/><br>
              แสดงความคิดเห็น
            </CButton>
          </a><br><br> -->
          
          <a target="_blank" href="https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1">
            <CButton shape="pill" color="pastelpurple2" style="height:90px;width:120px">
              <!-- <CIcon :height="1000" name="cib-line"/><br> -->
              <CIcon :height="1000" name="cil-comment-square"/><br>
              ขอความช่วยเหลือ
            </CButton>
          </a><br><br>
      </CCollapse>
      <div class="text-center">
        <CButton  color="pastelpurple2" @click="showBtn = !showBtn">
          <CIcon :height="1000" name="cil-comment-square"/>
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  }, 
  data () {
    return {
      showBtn: false
    }
  },   
  created() {
    // console.log("create container");
    // this.$route.meta.label = "test";
    // this.$route.name = this.eventName;
    // console.log(this.$route);
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>

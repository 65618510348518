<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://tlic.cmu.ac.th" target="_blank">LMS Management</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} TLIC, CMU.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a target="blank" href="https:tlic.cmu.ac.th">TLIC</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
<style scoped>
a {
  color: #8565c4;
}
</style>

<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <!--<CIcon 
        class="c-sidebar-brand-full" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 556 134"
      />-->
      <div class="c-avatar c-sidebar-brand-full pt-7 pb-7">
          <img
            src="img/TLIC/lms-logo-white-purplehat.png"
            width=auto 
            height="200"
          />
      </div>
      <div class="c-avatar c-sidebar-brand-minimized">
          <img
            src="img/TLIC/lms-logo-white-minimize.png"
            width=auto 
            height="38"
          />
      </div>
      <!--<CIcon 
        class="c-sidebar-brand-minimized" 
        name="logo" 
        size="custom-size" 
        :height="35" 
        viewBox="0 0 110 134"
      />-->
    </CSidebarBrand>

    <!-- <CRenderFunction v-if="showAdmin" flat :content-to-render="$options.navAdmin"/>
    <CRenderFunction v-else-if="!showMango && !showAdmin" flat :content-to-render="$options.nav"/>
    <CRenderFunction v-else-if="showMango && !showAdmin" flat :content-to-render="$options.navMango"/> -->

    <CRenderFunction flat :content-to-render="$options.nav"/>


    <!-- <CRenderFunction flat :content-to-render="$options.navMango"/> -->
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'
import navMango from './_nav_mango'
import navAdmin from './_nav_admin'
import Api from "@/services/Api"

export default {
  name: 'TheSidebar',
  nav,
  navMango,
  navAdmin,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  },
  data () {
    return {
      showMango:false,
      showAdmin:false
    }
  },
  created() {
    this.checkGrant(localStorage.getItem('email'))
  },
  methods: {
    async checkGrant(email){
      let grantList = await Api.checkGrant(email)
      if(grantList){
        console.log("grantList(API):",grantList)
        for(let i=0;i<grantList.length;i++){
          let adminTrigger = false
          switch (grantList[i].grant_on) {
            case "admin":
              this.showAdmin = true
              adminTrigger = true
              break;
            case "mango":
              this.showMango = true
              break;
            default:
              break;
          }
          if(adminTrigger){
            break;
          }
        }
      }
    },
  }

}
</script>

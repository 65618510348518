export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'ข่าวสารและประชาสัมพันธ์',
        to: '/',
        fontIcon: 'fas fa-newspaper',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['สำหรับผู้ดูแลระบบ']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'เปลี่ยน Email เป็นอาจารย์',
        to: '/change-email',
        fontIcon: 'fas fa-people-arrows'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'เปิดคอร์สให้อาจารย์',
        to: '/create-course',
        fontIcon: 'fas fa-hands-helping'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Learning management system']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'KC-Moodle',
        to: '/kc-moodle',
        fontIcon: 'fas fa-book-reader'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Canvas',
        to: '/canvas',
        fontIcon: 'fab fa-cuttlefish',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Mango',
        to: '/mango',
        fontIcon: 'icon-Mango2'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Exam CMU',
        to: '/exam',
        fontIcon: 'fas fa-graduation-cap',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['ช่วยเหลือ']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'แนวทางการแก้ไขปัญหา',
        to: '/troubleshooting',
        fontIcon: 'fas fa-question-circle'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'ขอความช่วยเหลือ',
        href: 'https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1',
        fontIcon: 'fas fa-comments',
        target: '_blank'
      },
    ]
  }
]